<template>

  <div @click="displayDishCard(dish)"
       class="card dish_card"
       :style="{
         'border': borderColor,
         'backgroundColor': isSelected ? 'rgba(242, 243, 247, .3)' : 'transparent',
       }"
       :class="{ 'is-shadowless': isShadowless }">

    <div class="card-content p-4">

      <div class="is-flex is-justify-content-space-between">

        <div>
          <h3 class="is-size-6">{{ dish.name }}</h3>

          <p class="is-size-6 has-text-grey">
            {{ truncateString(dish.description, 70) }}
          </p>
        </div>

        <div class="pl-3"
             style="min-width: 130px; max-width: 130px; position: relative;"
             v-if="dish.image">
          <b-image :alt="`${dish.name} picture`"
                   :src="dish.image.url"
                   lazy
                   ratio="1by1"
                   class="m-0">
            <template #placeholder>
              <b-skeleton
                class="skeleton-placeholder"
                height="100%"
              />
            </template>
          </b-image>
        </div>

      </div>

      <div class="has-text-grey mt-4">

        <!-- Case unit price -->
        <div v-if="!dish.multiPrice">
          <span v-if="dish.price.isSpecialOffer">
            <span class="crossed_out_price mr-4">{{ formatedPrice(dish.price.value) }}</span>
            <b-tag type="is-danger" rounded>
              {{ formatedPrice(dish.price.specialOffer) }}
            </b-tag>
          </span>
          <span v-else> {{ formatedPrice(dish.price.value) }}</span>
        </div>

        <!-- Case multi prices -->
        <div v-else>
          <ul class="price_list m-0">
            <li v-for="price in dish.prices" :key="price._id">
              <span class="mr-4 price_label is-italic">
                {{ truncateString(price.label, 15) }}
              </span>
              <span v-if="price.isSpecialOffer">
                <span class="crossed_out_price mr-4">{{ formatedPrice(price.value) }}</span>
                <b-tag type="is-danger" rounded>
                  {{ formatedPrice(price.specialOffer) }}
                </b-tag>
              </span>
              <span v-else>{{ formatedPrice(price.value) }}</span>
            </li>
          </ul>
        </div>

      </div>

      <div v-if="dish.tags.length > 0"
            style="width: 100%;"
            class="mt-3 is-flex is-flex-wrap-wrap label-tags">
        <div v-for="tag in dish.tags"
             :key="tag._id"
             :style="{ color: tag.color }"
             class="tag is-white mb-0 mr-1 pl-0 pr-4">
          <span style="font-size: 1.2em;">{{ tag.label }}</span>
        </div>
      </div>

      <div v-if="dish.allergens.length > 0"
          style="width: 100%;"
          class="is-flex mt-3 is-flex-wrap-wrap">
        <b-taglist>
          <b-tag type="is-light"
                 v-for="allergen in dish.allergens"
                 :key="allergen._id">
            {{ allergen.label }}
          </b-tag>
        </b-taglist>
      </div>

    </div>

  </div>

</template>

<script>
import truncate from 'lodash.truncate';

export default {
  props: {
    dish: {
      type: Object,
      required: true,
    },
    isShadowless: {
      type: Boolean,
      default: () => false,
    },
    hasBorder: {
      type: Boolean,
      default: () => false,
    },
    isSelected: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    closable: false,
  }),
  computed: {
    borderColor() {
      if (!this.hasBorder) return 'none';
      return this.isSelected ? '1px solid hsl(0, 0%, 48%)' : '1px solid #e8ebeb';
    },
  },
  methods: {
    displayDishCard(dish) {
      this.$emit('card-click', dish);
    },
    truncateString(str, n = 150) {
      return truncate(str, {
        length: n,
        separator: /,? +/,
      });
    },
    formatedPrice(value) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .dish_card {
    border-radius: 10px;
    position: relative;
    transition: box-shadow 0.2s ease-in-out;
    max-width: 400px;

    ::v-deep .crossed_out_price {
      text-decoration: line-through;

    }

    ::v-deep .price_label {
      display: inline-block;
      width: 7em;
    }

    ::v-deep .price_list {
      list-style-type: none;
    }

    ::v-deep img {
      border-radius: 5px;
    }

    .label-tags {
      ::v-deep .tag:not(body) {
        background-color: transparent;
      }
    }

    &:hover {
      box-shadow: 0 5px 20px 0 rgba(0,0,0,.05);
      cursor: pointer;
    }
  }
</style>